import { PropsWithChildren } from 'react';
import { Link } from '@/navigation';

interface Props extends PropsWithChildren {
  internal?: boolean;
  trusted?: boolean;
  link: {
    href: string;
    as?: string;
    scroll?: boolean;
  };
  className?: string;
}

export default function FooterLink(props: Props) {
  const className =
    props.className ||
    'tw-text-sm tw-no-underline tw-text-greyscale-placeholder hover:tw-text-greyscale-pink';

  if (props.internal) {
    return (
      <Link
        // @ts-ignore
        href={props.link.href}
        scroll={props.link.scroll}
        className={className}
      >
        {props.children}
      </Link>
    );
  }

  if (props.trusted) {
    return (
      <a className={className} href={props.link.href} target="_blank">
        {props.children}
      </a>
    );
  }

  return (
    <a
      className={className}
      href={props.link.href}
      rel="nofollow"
      target="_blank"
    >
      {props.children}
    </a>
  );
}
