import { User } from '@winelivery-org/wl-client-sdk-js';
import { createStore } from 'zustand';

export interface SessionProps {
  user?: User;
  token?: string;
}

export interface SessionActions {
  setUser: (user: User) => void;
  deleteSession: () => void;
  setSession: (args: { user: User; token: string }) => void;
}

export interface SessionState extends SessionProps, SessionActions {}

export type SessionStore = ReturnType<typeof createSessionStore>;

export const initialState: SessionProps = {};

export const createSessionStore = (initProps?: Partial<SessionProps>) => {
  return createStore<SessionState>((set, get) => ({
    ...initialState,
    ...initProps,
    setUser: (update: User) => {
      set(({ user }) => ({ user: { ...user, ...update } }));
    },
    deleteSession: () => {
      set({ user: undefined, token: undefined });
    },
    setSession: ({ user, token }) => {
      set({ user, token });
    },
  }));
};
