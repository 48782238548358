'use client';

import BottomLinks from './BottomLinks';
import Column from './Column';
import ContactSection from './ContactSection';
import Copyright from './Copyright';
import Section from './Section';
import useSections from './useSections';

const Footer = () => {
  const sections = useSections();
  return (
    <footer className="tw-bg-greyscale-body tw-mt-auto">
      <div className="tw-container tw-py-8 tw-px-4 lg:tw-px-6 xl:tw-px-8 tw-mx-auto lg:tw-space-y-6 lg:tw-divide-y tw-divide-greyscale-label">
        <div className="tw-flex tw-flex-col lg:tw-grid lg:tw-grid-cols-4">
          <Column>
            <ContactSection />
          </Column>

          <Column>
            <Section data={sections.discoverWinelivery} />
            <Section data={sections.buyProducts} />
          </Column>

          <Column>
            <Section data={sections.loyaltyProgram} />
          </Column>

          <Column>
            <Section data={sections.joinTheNetwork} />
          </Column>
        </div>

        <div className="lg:tw-pt-5 tw-space-y-4">
          <BottomLinks />
          <Copyright />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
