import {
  createLocalizedPathnamesNavigation,
  Pathnames,
} from 'next-intl/navigation';

export const locales = ['de'] as const;
export const defaultLocale = 'de';
export const localePrefix = 'always'; // Default

export const pathnames = {
  '/': '/',
  '/auth/signin': '/auth/signin',
  '/auth/signup': '/auth/signup',
  '/privacy': '/privacy',
  '/checkout': '/checkout',
  '/email-unsubscribe': '/email-unsubscribe',
  '/giftcard': '/giftcard',
  '/riscatta-giftcard': '/riscatta-giftcard',
  '/reset-password': '/reset-password',
  '/about': {
    de: '/wer-wir-sind',
  },
  '/ourstory': {
    de: '/unsere-geschichte',
  },
  '/press': {
    de: '/presse',
  },
  '/gifts': {
    de: '/werbegeschenke',
  },
  '/terms': {
    de: '/terms-and-conditions',
  },
  '/produttori': {
    de: '/hersteller',
  },
  '/diventa-nostro-affiliato': {
    de: '/werden-sie-unser-partner',
  },
  '/campaign/[slug]': {
    de: '/kampagne/[slug]',
  },
  '/prova-winelivery': {
    de: '/test-winelivery',
  },
  '/scopri-winelivery': {
    de: '/entdecke-winelivery',
  },
  '/diventa-nostro-rider': {
    de: '/werde-unser-rider',
  },
  '/create-your-cocktail': {
    de: '/kreiere-deinen-eigenen-cocktail',
  },
  '/create-your-cocktail/[flow]/review': {
    de: '/kreiere-deinen-eigenen-cocktail/[flow]/review',
  },
  '/create-your-cocktail/[flow]/[step]': {
    de: '/kreiere-deinen-eigenen-cocktail/[flow]/[step]',
  },
  '/direttamente-dal-produttore': {
    de: '/direkt-vom-hersteller',
  },
  '/fai-un-regalo': {
    de: '/ein-geschenk-geben',
  },
  '/delivery/[slug]': {
    de: '/lieferung/[slug]',
  },
  '/business/eventi-aziendali': {
    de: '/business/firmenveranstaltungen',
  },
  '/business/eventi-privati': {
    de: '/business/privatveranstaltungen',
  },
  '/business/eventi-aziendali/eventi-virtuali': {
    de: '/business/firmenevents/virtuelle-events',
  },
  '/business/eventi-aziendali/eventi-in-presenza': {
    de: '/business/firmenveranstaltungen/personliche-veranstaltungen',
  },
  '/business/eventi-aziendali/regalistica-aziendale': {
    de: '/business/firmenevents/firmengeschenke',
  },
  '/bar': {
    de: '/bar-und-weinladen',
  },
  '/bar/products': {
    de: '/bar-und-weinladen/produkte',
  },
  '/bar/[barId]': {
    de: '/bar-und-weinladen/[barId]',
  },
  '/faq': {
    de: '/faq',
  },
  '/shop': {
    de: '/produkte',
  },
  '/shop/[id]': {
    de: '/einzelheiten/[id]',
  },
  '/partners/[slug]': '/partners/[slug]',
  '/category[category]': {
    de: '/kategorie/[category]',
  },
  '/profile': {
    de: '/profil',
  },
  '/profile/giftcards': {
    de: '/profil/gutscheine',
  },
  '/profile/addresses': {
    de: '/profil/adressen',
  },
  '/profile/payment-methods': {
    de: '/profil/zahlungsmethoden',
  },
  '/profile/orders': {
    de: '/profil/bestellungen',
  },
  '/profile/orders/[id]': {
    de: '/profil/bestellungen/[id]',
  },
  '/profile/orders/experiences/[id]': {
    de: '/profil/bestellungen/erlebnisse/[id]',
  },
  '/experience': {
    de: '/erlebnisse',
  },
  '/experience/experience-detail/[id]': {
    de: '/erlebnisse/erlebnis-detail/[id]',
  },
  '/experience/provider-detail/[id]': {
    de: '/erlebnisse/anbieter-detail/[id]',
  },
  '/experience/checkout-complete': {
    de: '/erlebnisse/checkout-abgeschlossen',
  },
  '/experience/checkout': {
    de: '/erlebnisse/checkout',
  },
  '/wallet': {
    de: '/brieftasche',
  },
  '/wallet/checkout': {
    de: '/brieftasche/checkout',
  },
  '/wallet/shop': {
    de: '/brieftasche/shop',
  },
  '/wallet/prize/[id]': {
    de: '/brieftasche/preis/[id]',
  },
  '/profile/wishlist': {
    de: '/profil/wunschliste',
  },
  '/profile/recently-purchased': {
    de: '/profil/kurzlich-gekauft',
  },
  '/search-results': {
    de: '/suchergebnisse',
  },
} satisfies Pathnames<typeof locales>;

export const {Link, redirect, usePathname, useRouter, getPathname} =
  createLocalizedPathnamesNavigation({locales, localePrefix, pathnames});
