'use client';

import { LocalizationContext } from '@/stores/localization/context';
import {
  LocalizationProps,
  LocalizationStore,
  createLocalizationStore,
} from '@/stores/localization/store';
import { useRef } from 'react';

type LocalizationProviderProps = React.PropsWithChildren<
  Partial<LocalizationProps>
>;

export default function LocalizationProvider({
  children,
  ...props
}: LocalizationProviderProps) {
  const storeRef = useRef<LocalizationStore>();
  if (!storeRef.current) {
    storeRef.current = createLocalizationStore(props);
  }

  return (
    <LocalizationContext.Provider value={storeRef.current}>
      {children}
    </LocalizationContext.Provider>
  );
}
