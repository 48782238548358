import {AZ, City} from '@winelivery-org/wl-client-sdk-js';
import axios from 'axios';
import {getApisRoot, getHeaders} from '../common';

export const getCities = async () => {
  const baseUrl = getApisRoot('wl-localization-service', 'localization');
  const response = await axios.get<City[]>(`${baseUrl}/v1/cities/`, {
    headers: await getHeaders(),
  });
  return response.data;
};

export async function getAzs(params: {geoHash: string; deliveryType?: string}) {
  const baseUrl = getApisRoot('wl-localization-service', 'localization');
  const response = await axios.get<AZ[]>(`${baseUrl}/v1/azs/`, {
    headers: await getHeaders(),
    params: {geo_hash: params.geoHash, delivery_type: params.deliveryType},
  });
  return response.data;
}
