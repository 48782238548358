import {User} from '@winelivery-org/wl-client-sdk-js';
import moment from 'moment';
import logger from '../logger';

export const init = (user?: User) => {
  if (process.env.NODE_ENV === 'production') {
    // @ts-ignore
    Leanplum.setAppIdForProductionMode(
      process.env.NEXT_PUBLIC_LEANPLUM_APP_ID,
      process.env.NEXT_PUBLIC_LEANPLUM_KEY,
    );
  } else {
    // @ts-ignore
    Leanplum.setAppIdForDevelopmentMode(
      process.env.NEXT_PUBLIC_LEANPLUM_APP_ID,
      process.env.NEXT_PUBLIC_LEANPLUM_KEY,
    );
  }

  // @ts-ignore
  Leanplum.start((success) => {
    if (user) {
      saveUser(user);
    }
    // @ts-ignore
    Leanplum.enableRichInAppMessages(true);
  });
};

export const saveUser = (user: User) => {
  try {
    let city;

    // @ts-ignore
    if (user.city && user.city.name) {
      // @ts-ignore
      city = user.city.name;
    }

    const birthdate = user.birthdate ? moment(user.birthdate) : undefined;

    // @ts-ignore
    Leanplum.setUserId(user._id);
    // @ts-ignore
    Leanplum.setUserAttributes({
      name: `${user.name} ${user.lastname}`,
      email: user.email,
      strategy: user.strategy || '',
      group: user.group || '',
      marketingAgreed: user.marketingAgreed || false,
      phone: user.phone || '',
      ...(birthdate && {birthdate: parseInt(birthdate.format('YYYYMMDD'))}),
      ...(birthdate && {dob: birthdate.format('YYYY-MM-DD')}),
    });
  } catch (err) {
    logger.error(err);
  }
};

export const recordEvent = (event: string, args: object = {}): void => {
  try {
    // @ts-ignore
    Leanplum.track(event, args);
  } catch (err) {
    logger.error(err);
  }
};
