import { useTranslations } from 'next-intl';
import Divider from './Divider';

export default function ContactSection() {
  const t = useTranslations('common');
  return (
    <div className="tw-order-last">
      <section>
        <h3 className="tw-text-base tw-font-medium tw-text-greyscale-offwhite tw-mb-2 tw-tracking-tight">
          {t('Footer.section.contact-us')}
        </h3>

        <ul className="tw-m-0 tw-p-0 tw-space-y-4 sm:tw-space-y-0 lg:tw-space-y-4 sm:tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-block">
          <li>
            <h4 className="tw-text-sm tw-font-medium tw-text-greyscale-separator tw-mb-1 tw-tracking-tight">
              {t('Footer.section.customer-care')}
            </h4>
            <a
              className="tw-text-sm hover:tw-underline tw-text-greyscale-placeholder hover:tw-text-greyscale-pink"
              href="mailto:assistenza@winelivery.com"
            >
              assistenza@winelivery.com
            </a>
          </li>

          <li>
            <h4 className="tw-text-sm tw-font-medium tw-text-greyscale-separator tw-mb-1 tw-tracking-tight">
              {t('Footer.section.press')}
            </h4>
            <a
              className="tw-text-sm hover:tw-underline tw-text-greyscale-placeholder hover:tw-text-greyscale-pink"
              href="mailto:press@winelivery.com"
            >
              press@winelivery.com
            </a>
          </li>

          <li>
            <h4 className="tw-text-sm tw-font-medium tw-text-greyscale-separator tw-mb-2 tw-tracking-tight">
              {t('Footer.section.follow-us')}
            </h4>
            <ul className="tw-flex tw-p-0 tw-m-0 tw-space-x-2">
              <li>
                <a
                  className="social-button shape-circle sb-facebook sb-light-skin"
                  target="_blank"
                  href="https://www.facebook.com/winelivery/"
                  rel="nofollow"
                >
                  <i className="socicon-facebook" />
                </a>
              </li>
              <li>
                <a
                  className="social-button shape-circle sb-instagram sb-light-skin"
                  target="_blank"
                  href="https://www.instagram.com/winelivery/"
                  rel="nofollow"
                >
                  <i className="socicon-instagram" />
                </a>
              </li>
              <li>
                <a
                  className="social-button shape-circle sb-instagram sb-light-skin"
                  target="_blank"
                  href="https://www.youtube.com/channel/UCyZEX3vU17uM8WB_ORo6yUg"
                  rel="nofollow"
                >
                  <i className="socicon-youtube" />
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </section>
      <Divider />
    </div>
  );
}
