import { sendGTMEvent } from '@next/third-parties/google';
import { SearchProduct } from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import Crypto from 'crypto-js';
import { CartItem } from '../stores/cart/types';
import { CheckoutState } from '../stores/checkout/types';

export const addEmail = (email: string): void => {
  sendGTMEvent({
    email,
    cryptedEmail: Crypto.SHA256(email).toString(),
  });
};

export const addToCart = (product: SearchProduct, quantity?: number): void => {
  sendGTMEvent({
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price,
            category: product.category ? product.category.slug || '' : '',
            subcategory: product.subcategory
              ? product.subcategory.slug || ''
              : '',
            quantity: quantity || 1,
            slug: product.slug,
          },
        ],
      },
    },
  });
};

export const viewCategory = (category?: string, subcategory?: string) => {
  if (!category && !subcategory) {
    return;
  }

  sendGTMEvent({
    event: 'viewCategory',
    category,
    subcategory,
  });
};

export const signup = (email: string) => {
  sendGTMEvent({
    event: 'signup',
    email,
  });
};

export const search = (query: string) => {
  sendGTMEvent({
    event: 'search',
    search_query: query,
  });
};

export const initiateCheckout = (total: number): void => {
  sendGTMEvent({
    event: 'initiateCheckout',
    initiateCheckoutValue: total,
  });
};

export const viewContent = (product: SearchProduct): void => {
  sendGTMEvent({
    event: 'viewContent',
    viewContent: {
      ...product,
      category: product.category ? product.category.slug || '' : '',
      subcategory: product.subcategory ? product.subcategory.slug || '' : '',
    },
  });
};

export const purchase = (checkout: CheckoutState): void => {
  sendGTMEvent({
    event: 'purchase',
    ecommerce: {
      purchase: {
        actionField: {
          id: checkout.orderId,
          revenue: checkout.totalOrder.toFixed(2),
          shipping: checkout.computedDeliveryCost.toFixed(2),
          coupon: checkout.coupon.code || '',
          store:
            checkout.az &&
            checkout.az.storesIds &&
            checkout.az.storesIds.length > 0
              ? checkout.az.storesIds[0]
              : '',
          products: checkout.items.map((i) => ({
            name: i.product.name,
            id: i.product.id,
            price: i.product.discountedPrice || i.product.price,
            category: i.product.category ? i.product.category.slug : '',
            subcategory: i.product.subcategory
              ? i.product.subcategory.slug
              : '',
            quantity: i.quantity,
            slug: i.product.slug,
          })),
          ordersCount: checkout.ordersCount || 0,
        },
      },
    },
  });
};

export const impressions = (products: SearchProduct[]): void => {
  sendGTMEvent({
    ecommerce: {
      currencyCode: 'EUR',
      impressions: products.map((product, index) => ({
        name: product.name,
        id: product.id,
        price: product.price,
        category: product.category ? product.category.slug : '',
        subcategory: product.subcategory ? product.subcategory.slug : '',
        position: index + 1,
      })),
    },
  });
};

export const checkoutProducts = (products: CartItem[]): void => {
  sendGTMEvent({
    event: 'checkout',
    ecommerce: {
      checkout: {
        actionField: { step: 1 },
        products: products.map((product) => ({
          name: product.product.name,
          id: product.product.id,
          price: product.product.price,
          category: product.product.category
            ? product.product.category.slug
            : '',
          subcategory: product.product.subcategory
            ? product.product.subcategory.slug
            : '',
          quantity: product.quantity,
          slug: product.product.slug,
        })),
      },
    },
  });
};

export const detail = (product: SearchProduct): void => {
  sendGTMEvent({
    event: 'detail',
    ecommerce: {
      detail: {
        products: [
          {
            name: product.name,
            id: product.id,
            price: product.price,
            category: product.category ? product.category.slug : '',
            subcategory: product.subcategory ? product.subcategory.slug : '',
            quantity: 1,
            slug: product.slug,
          },
        ],
      },
    },
  });
};
