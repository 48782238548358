'use client';

import { ToastContainer } from 'react-toastify';

export default function () {
  return (
    <ToastContainer
      toastClassName={(props) =>
        ({
          success: 'tw-bg-success',
          error: 'tw-bg-error tw-text-greyscale-offwhite',
          info: 'tw-bg-warning-light tw-text-warning-dark',
          warning: 'tw-bg-warning-light tw-text-warning-dark',
          default: 'tw-bg-primary',
          dark: 'tw-bg-white-600 tw-font-gray-300',
        }[props?.type || 'default'] +
        ' tw-relative tw-flex tw-p-1 tw-min-h-10 tw-rounded-md tw-justify-between tw-overflow-hidden tw-cursor-pointer')
      }
      hideProgressBar={false}
      pauseOnFocusLoss={false}
      autoClose={3000}
      newestOnTop
      position={'top-right'}
      theme={'colored'}
    />
  );
}
