import { DeliveryType } from '@/models/az';
import { ILocation } from '@/models/location';
import { getAzs } from '@/services/localization';
import { AZ } from '@winelivery-org/wl-client-sdk-js';
import geohash from 'ngeohash';
import { createStore } from 'zustand';
import { update } from '../../services/user';
import useSession from '../session/useSession';

interface FetchAzsRequest {
  lat: number;
  lng: number;
  deliveryType?: DeliveryType;
}

export interface LocalizationProps {
  geoHash?: string;
  hash: string;
  deliveryType;
  azs: AZ[];
  location?: ILocation;
}

export interface LocalizationState extends LocalizationProps {
  setState: (props: Partial<LocalizationProps>) => void;
  getState: () => LocalizationState;
  fetchAzs: (request: FetchAzsRequest) => Promise<AZ[]>;
  setAzs: (azs: AZ[]) => void;
  updateUserLocation: () => Promise<void>;
  initAzs: () => Promise<void>;
}

export type LocalizationStore = ReturnType<typeof createLocalizationStore>;

export const initialState: LocalizationProps = {
  geoHash: '',
  hash: process.env.NEXT_PUBLIC_DEFAULT_STORE_ID!,
  deliveryType: 'ecommerce',
  azs: [],
};

export const createLocalizationStore = (
  initProps?: Partial<LocalizationProps>,
) => {
  return createStore<LocalizationState>((set, get) => ({
    ...initialState,
    ...initProps,
    setState: (props: Partial<LocalizationProps>) => set(props),
    getState: () => get(),
    fetchAzs: async ({ lat, lng, deliveryType }: FetchAzsRequest) => {
      const geoHash = ngeohash.encode(lat, lng);
      return getAzs({
        geoHash,
        deliveryType,
      });
    },
    initAzs: async () => {
      const lat = get().location?.coordinates?.lat || 45.461487;
      const lng = get().location?.coordinates?.lng || 9.189327;
      const response = await get().fetchAzs({ lat, lng });
      get().setAzs(response);
      const { user } = useSession();
      if (response[0] && !!user) {
        get().updateUserLocation();
      }
    },
    setAzs: (azs: AZ[]) => set({ azs }),
    updateUserLocation: async () => {
      const { azs, location } = get();
      const { user } = useSession();

      if (azs.length === 0 || !user || user.location || !location) {
        return;
      }

      const { coordinates, formattedAddress } = location;
      const geoHash = geohash.encode(coordinates.lat!, coordinates.lng!);

      const { ok, data } = await update({
        location: {
          storeName: azs[0].storeName,
          latitude: coordinates.lat!,
          longitude: coordinates.lng!,
          geoHash,
          storeId: azs[0].storesIds[0],
          address: formattedAddress,
        },
      });
      if (ok) {
        const { setUser, user } = useSession();
        if (user) {
          setUser({ ...user, location: data.location });
        }
      }
    },
  }));
};
