'use client';

import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export function RouteChangeListener() {
  const pathname = usePathname();

  useEffect(() => {
    window.dataLayer?.push({ event: 'pageview' });
  }, [pathname]);

  return <></>;
}
