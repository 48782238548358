interface Link {
  title: string;
  internal?: boolean;
  trusted?: boolean;
  url: {
    href: string;
    scroll?: boolean;
  };
}

export interface Section {
  title: string;
  links: Link[];
}

export default function useSections() {
  return {
    discoverWinelivery: {
      title: 'discover-winelivery',
      links: [
        {
          title: 'faq',
          internal: true,
          url: {
            href: '/faq',
          },
        },
      ],
    },
    buyProducts: {
      title: 'buy-products',
      links: [
        {
          title: 'shop',
          url: { href: '/shop' },
          internal: true
        },
        {
          title: 'experiences',
          url: { href: '/experience' },
          internal: true,
        },
        {
          title: 'giftcard',
          url: {
            href: '/giftcard',
          },
          internal: true,
        },
      ],
    },

    corporateServices: {
      title: 'corporate-services',
      links: [
        {
          title: 'company-gifts',
          internal: true,
          url: {
            href: '/business/eventi-aziendali/regalistica-aziendale',
          },
        },
        {
          title: 'corporate-events',
          internal: true,
          url: {
            href: '/business/eventi-aziendali/eventi-in-presenza',
          },
        },
        {
          title: 'corporate-virtual-events',
          internal: true,
          url: {
            href: '/business/eventi-aziendali/eventi-virtuali',
          },
        },
      ],
    },
    loyaltyProgram: {
      title: 'wallet-program',
      links: [
        {
          title: 'wallet',
          internal: true,
          url: {
            href: '/wallet',
          },
        },
        {
          title: 'cork-shop',
          internal: true,
          url: { href: '/wallet/shop' },
        },
        {
          title: 'referral',
          url: {
            href: '/wallet#referral',
            scroll: false,
          },
          internal: true,
        },
      ],
    },
    communityPartners: {
      title: 'community-partners',
      links: [
        {
          title: 'becomesomm',
          internal: false,
          trusted: true,
          url: { href: 'https://www.becomesomm.com/' },
        },
      ],
    },
    joinTheNetwork: {
      title: 'join-the-network',
      links: [
        {
          title: 'producers',
          internal: true,
          url: { href: '/produttori' },
        },
        {
          title: 'open-a-franchising',
          internal: true,
          url: {
            href: '/diventa-nostro-affiliato',
          },
        },
      ],
    },
    workWithUs: {
      title: 'work-with-us',
      links: [
        {
          title: 'become-a-rider',
          internal: true,
          url: {
            href: '/diventa-nostro-rider',
          },
        },
      ],
    },
  };
}
