import SectionContainer from './SectionContainer';
import { Section as SectionT } from './useSections';
import FooterLink from './FooterLink';
import Divider from './Divider';
import { useTranslations } from 'next-intl';

interface Props {
  data: SectionT;
}

export default function Section(props: Props) {
  const t = useTranslations('common');
  return (
    <>
      <SectionContainer title={t(`Footer.section.${props.data.title}`)}>
        {props.data.links.map((item) => (
          <li key={item.title}>
            <FooterLink
              internal={item.internal}
              trusted={item.trusted}
              link={item.url}
            >
              {t(`Footer.link.${item.title}`)}
            </FooterLink>
          </li>
        ))}
      </SectionContainer>
      <Divider />
    </>
  );
}
