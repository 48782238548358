import {getApisRoot, getHeaders} from '../common';

const baseUrl = getApisRoot('wl-user-service', 'user');

export const createSignupIntent = async (email: string, strategy: string) => {
  const response = await fetch(`${baseUrl}/v1/signup-intent`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({email, strategy}),
  });

  const data = await response.json();

  return {ok: response.ok, status: response.status, data};
};

export const patchSignupIntent = async (
  token: string,
  phoneNumber: string,
  code?: string,
) => {
  const response = await fetch(`${baseUrl}/v1/signup-intent/${token}`, {
    method: 'PATCH',
    headers: await getHeaders(),
    body: JSON.stringify({phoneNumber, code}),
  });
  const data = await response.json();
  return {ok: response.ok, status: response.status, data};
};

export const completeSignupIntent = async (token: string, args: any) => {
  const response = await fetch(`${baseUrl}/v1/signup-intent/${token}`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(args),
  });

  const data = await response.json();
  return {ok: response.ok, status: response.status, data};
};

import {
  Address,
  GetAddressesResponse,
  GetGiftcardsResponse,
  Giftcard,
  PatchMeRequest,
  User,
  UserOrdersResponse,
  UserPaymentMethodResponse,
  UserSetupPaymentMethodResponse,
} from '@winelivery-org/wl-client-sdk-js';
import {UserOrder} from '@winelivery-org/wl-client-sdk-js/dist/models/apis';
import {DeletionRequest} from '@winelivery-org/wl-client-sdk-js/dist/models/deletionRequest';
import {GetSearchProductsResponse} from '@winelivery-org/wl-client-sdk-js/dist/models/search';
import * as leanplum from '../../lib/leanplum';

export const updateUser = async (user: Partial<User>) => {
  const response = await fetch(`${baseUrl}/v2/me`, {
    method: 'PATCH',
    headers: await getHeaders(),
    body: JSON.stringify(user),
  });

  const data = await response.json();
  if (response.ok) {
    leanplum.saveUser(data);
  }

  return {ok: response.ok, status: response.status, data};
};

export const getMe = async () => {
  const response = await fetch(`${baseUrl}/v2/me`, {
    headers: await getHeaders(),
  });

  const data = await response.json();
  return {ok: response.ok, status: response.status, data};
};

export const addToWishList = async (productId: string) => {
  const response = await fetch(`${baseUrl}/v2/me/wishlist`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({productId}),
  });

  const data = await response.json();
  return {ok: response.ok, status: response.status, data};
};

export const removeFromWishList = async (productId: string) => {
  const response = await fetch(`${baseUrl}/v2/me/wishlist/${productId}`, {
    method: 'DELETE',
    headers: await getHeaders(),
  });

  return {ok: response.ok, status: response.status};
};

export const getWishlistProducts = async (
  hash: string,
  deliveryType: string = 'express',
) => {
  const response = await fetch(
    `${baseUrl}/v2/me/wishlist?hash=${hash}&deliveryType=${deliveryType}`,
    {
      method: 'GET',
      headers: await getHeaders(),
    },
  );

  const data = await response.json();
  return {ok: response.ok, status: response.status, data};
};

export const saveAddress = async (address: Address) => {
  const response = await fetch(`${baseUrl}/v2/me/addresses`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(address),
  });

  const data = response.ok
    ? ((await response.json()) as Address)
    : ((await response.json()) as {message: string});

  return {ok: response.ok, status: response.status, data};
};

export const getGitcards = async () => {
  const response = await fetch(`${baseUrl}/v2/me/giftcards`, {
    method: 'GET',
    headers: await getHeaders(),
  });

  const data = (await response.json()) as GetGiftcardsResponse;
  return {ok: response.ok, status: response.status, data};
};

export const attachGiftcard = async (code: string) => {
  const response = await fetch(`${baseUrl}/v2/me/giftcards`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify({code}),
  });

  const data = (await response.json()) as Giftcard;
  return {ok: response.ok, status: response.status, data};
};

export const deleteAddress = async (addressId: string) => {
  const response = await fetch(`${baseUrl}/v2/me/addresses/${addressId}`, {
    method: 'DELETE',
    headers: await getHeaders(),
  });

  return {ok: response.ok, status: response.status};
};

export const getAddresses = async () => {
  const response = await fetch(`${baseUrl}/v2/me/addresses`, {
    method: 'GET',
    headers: await getHeaders(),
  });
  const data = (await response.json()) as GetAddressesResponse;
  return {ok: response.ok, status: response.status, data};
};

export const createDeletionRequest = async (body: {reason: string}) => {
  const response = await fetch(`${baseUrl}/v2/deletion-requests`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(body),
  });
  const data = (await response.json()) as DeletionRequest;
  return {ok: response.ok, status: response.status, data};
};

export const getOrderById = async (id: string | number) => {
  const response = await fetch(`${baseUrl}/v2/me/orders/${id}`, {
    method: 'GET',
    headers: await getHeaders(),
  });
  const data = (await response.json()) as UserOrder;
  return {ok: response.ok, status: response.status, data};
};

export const getOrders = async ({
  page,
  status,
}: {
  page: number;
  status: string;
}) => {
  const response = await fetch(
    `${baseUrl}/v2/me/orders?page=${page}&status=${status}`,
    {
      method: 'GET',
      headers: await getHeaders(),
    },
  );
  const data = (await response.json()) as UserOrdersResponse;
  return {ok: response.ok, status: response.status, data};
};

export const getPaymentMethods = async () => {
  const response = await fetch(`${baseUrl}/v2/me/payment-methods`, {
    method: 'GET',
    headers: await getHeaders(),
  });
  const data = (await response.json()) as UserPaymentMethodResponse;
  return {ok: response.ok, status: response.status, data};
};

export const deletePaymentMethod = async (id: string, method?: string) => {
  const response = await fetch(
    `${baseUrl}/v2/me/payment-methods/${id}${
      method ? `?method=${method}` : ''
    }`,
    {
      method: 'DELETE',
      headers: await getHeaders(),
    },
  );
  return {ok: response.ok, status: response.status};
};

export const getPurchases = async ({
  hash,
  deliveryType,
}: {
  hash: string;
  deliveryType: string;
}) => {
  const response = await fetch(
    `${baseUrl}/v2/me/purchases?hash=${hash}&deliveryType=${deliveryType}`,
    {
      method: 'GET',
      headers: await getHeaders(),
    },
  );
  const data = (await response.json()) as GetSearchProductsResponse;
  return {ok: response.ok, status: response.status, data};
};

export const setupPaymentMethod = async (body: {method: string}) => {
  const response = await fetch(`${baseUrl}/v2/me/setup-payment-methods`, {
    method: 'POST',
    headers: await getHeaders(),
    body: JSON.stringify(body),
  });
  const data = (await response.json()) as UserSetupPaymentMethodResponse;
  return {ok: response.ok, status: response.status, data};
};

export const update = async (body: PatchMeRequest) => {
  const response = await fetch(`${baseUrl}/v2/me`, {
    method: 'PATCH',
    headers: await getHeaders(),
    body: JSON.stringify(body),
  });
  const data = (await response.json()) as User;
  return {ok: response.ok, status: response.status, data};
};

update;
