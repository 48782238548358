'use client';

import { useContext } from 'react';
import { useStore } from 'zustand';
import { SessionContext } from './context';

export default function useSession() {
  const store = useContext(SessionContext);
  if (!store) {
    throw new Error('Missing SessionContext.Provider in the tree');
  }

  return useStore(store);
}
