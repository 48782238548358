import { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  title: string;
}

export default function SectionContainer(props: Props) {
  return (
    <section>
      <h3 className="tw-text-base tw-font-medium tw-text-greyscale-offwhite tw-mb-2 tw-tracking-tight">
        {props.title}
      </h3>
      <ul className="tw-m-0 tw-p-0 tw-space-y-1 sm:tw-grid sm:tw-grid-cols-2 md:tw-grid-cols-3 lg:tw-block">
        {props.children}
      </ul>
    </section>
  );
}
